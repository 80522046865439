.hideScroll::-webkit-scrollbar {
  display: none;
}

.smooth {
  transition: 0.5s;
}

.slideRight {
  animation: 3s slide-right;
}

.card:nth-child(2){
  transition-delay: 200ms;
}
.card:nth-child(3){
  transition-delay: 300ms;
}
.card:nth-child(4){
  transition-delay: 400ms;
}
.card:nth-child(5){
  transition-delay: 500ms;
}
.card:nth-child(6){
  transition-delay: 600ms;
}

@media(prefers-reduced-motion){
  .hidden{
    transition: none;
  }
}