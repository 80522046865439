.App {
  text-align: center;
  margin: 0;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.Amatic {
  font-family: 'Amatic SC', cursive;
}

h1 {
  font-size: 36px;
}

h2 {
  font-size: 30px;
}

h3 {
  font-size: 24px;
}

h4 {
  font-size: 18;
}

h5 {
  font-size: 14;
}

h6 {
  font-size: 12;
}

.bg {
  background-image: url(./assets/bg5.jpg);
  width: 100%;
  object-fit: contain;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  /* max-height: 100%; */
  height: 100%;
}

hideScroll::-webkit-scrollbar {
  display: none;
}

.wrapper {
  height: 100vh;
  /* overflow-y: auto; */
  overflow-x: hidden;
  perspective: 10px;
}

.header {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  transform-style: preserve-3d;
  z-index: -1;
}

.foreground,
.background {
  position: absolute;
  /* height: 100%; */
  top: 0;
  width: 100%;
  object-fit: cover;
  z-index: -1;
}

.background {
  transform: translateZ(-10px) scale(2);
}

.fredoka {
  font-family: 'Fredoka One', cursive;
}

.nunito {
  font-family: 'Nunito', sans-serif;
}

.hideScroll::-webkit-scrollbar{
  display: none;
}

.smooth{
  transition:  0.5s;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

@media (max-width: 1240px) {

  /* .bg{
    width: 100%;
    background-size: contain;
    height: 1038px;
  } */
  .bg {
    background-image: url(./assets/bg4.jpg);
    width: 100%;
    object-fit: contain;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
    /* max-height: 100%; */
    height: 100%;
  }
}